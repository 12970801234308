import {} from '@/shared/api';
import {
  GetProductsV1Request,
  PostPutProductsRequest,
  Product,
  PutProductsV1ProductIdError,
} from '@/shared/api/codegen/demuzo';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { productsKeys } from '../query-keys';
import { productApi } from '@/shared/api/config';

export const usePutProduct = (productId: string) => {
  const queryClient = useQueryClient();

  return useMutation<
    Product,
    PutProductsV1ProductIdError,
    PostPutProductsRequest
  >({
    mutationFn: (productBody) =>
      productApi.putProductsV1ProductId({
        postPutProductsRequest: productBody,
        productId,
      }),
    onMutate: async (artistRequest) => {
      await queryClient.cancelQueries({
        queryKey: productsKeys.getProductById(productId),
      });
      const previousProducts = queryClient.getQueryData<GetProductsV1Request>(
        productsKeys.getProductById(productId),
      );

      if (previousProducts && artistRequest) {
        queryClient.setQueryData<GetProductsV1Request>(
          productsKeys.getProductById(productId),
          (a) => ({
            ...previousProducts,
            ...artistRequest,
          }),
        );
      }

      return { previousProducts: previousProducts };
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: productsKeys.all,
      });
    },
  });
};
