import { LIMIT, OFFSET, REVALIDATE } from '@/entities/constants';
import {} from '@/shared/api';
import { FetchQueryOptions, useQuery } from '@tanstack/react-query';
import { mapApiProductToProduct } from '../lib';
import { productsKeys } from '../query-keys';
import { productApi } from '@/shared/api/config';

type Props = {
  enabled?: boolean;
  limit: number;
  offset: number;
};

export const getProductsPrefetchConfig = (): FetchQueryOptions => ({
  queryKey: [...productsKeys.getProducts({ limit: 10, offset: 0 })],
  queryFn: () =>
    productApi.getProductsV1(
      { limit: LIMIT, offset: OFFSET },
      { next: { revalidate: REVALIDATE } },
    ),
});

export const useGetProducts = ({ limit, offset, enabled }: Props) => {
  return useQuery({
    enabled,
    queryKey: [...productsKeys.getProducts({ limit, offset })],
    queryFn: async () => {
      const response = await productApi.getProductsV1({ offset, limit });

      const mappedProducts = response.items.map(mapApiProductToProduct);

      return {
        ...response,
        items: mappedProducts,
      };
    },
  });
};
