import { createIcon } from '@chakra-ui/react';
import { FC, memo } from 'react';
import { iconSizes } from './constants';
import { IconPropsWithSize } from './types';

const Ic = createIcon({
  viewBox: '0 0 24 24',
  path: (
    <path
      d='M14.7985 21.6249L6.00004 12.5002L14.7985 3.37549L16.0942 4.6249L8.50053 12.5002L16.0942 20.3755L14.7985 21.6249Z'
      fill='currentColor'
    />
  ),
});

const IconChevronLeft: FC<IconPropsWithSize> = ({ size = 'lg', ...props }) => (
  <Ic
    {...iconSizes[size]}
    {...props}
  />
);

export default memo(IconChevronLeft);
