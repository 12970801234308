'use client';

import Section from '@ui-kit/molecules/section/section';
import { Box, Flex } from '@chakra-ui/react';
import IconButton from '@ui-kit/atoms/icon-button/icon-button';
import IconChevronLeft from '@ui-kit/icons/icon-chevron-left';
import IconChevronRight from '@ui-kit/icons/icon-chevron-right';
import { useIsMobile } from '@ui-kit/hooks/useIsMobile';
import { MobileHomeFeaturedList } from '@/shared/components/mobile-home-featured-list';
import { DesktopHomeFeaturedList } from '@/shared/components/desktop-home-featured-list/desktop-home-featured-list';
import { useGetFeatured } from '@/entities/product';
import { MouseEvent, useState } from 'react';
import { Swiper } from 'swiper/types';
import { signIn } from 'next-auth/react';
import { useDeleteFavorite, usePostFavorite } from '@/entities/user';
import PageLoader from '@/shared/components/page-loader/page-loader';
import { useTranslations } from 'next-intl';
import { GetFeatured200 } from '@/shared/api/codegen/demuzo';

type TProps = {
  initData?: GetFeatured200;
};

export const FeaturedWidget = ({ initData }: TProps) => {
  const t = useTranslations();
  const isMobile = useIsMobile();

  const { data, isLoading } = useGetFeatured({ initData });
  const [controlledSwiperFeatured, setControlledSwiperFeatured] =
    useState<Swiper | null>(null);

  const handleSignIn = async () => {
    await signIn('keycloak');
  };

  const mutationSubscribe = usePostFavorite();
  const mutationUnSubscribe = useDeleteFavorite();

  const handleToggleFavoriteFeature = ({
    evt,
    productId,
    isFavorite,
    isLogged,
  }: {
    evt: MouseEvent<HTMLButtonElement>;
    productId: string;
    isFavorite: boolean;
    isLogged: boolean;
  }) => {
    evt.preventDefault();
    evt.stopPropagation();

    if (!isLogged) {
      handleSignIn();
      return;
    }

    if (!isFavorite) {
      mutationSubscribe.mutate(productId);
      return;
    }

    mutationUnSubscribe.mutate(productId);
  };

  return (
    <Section
      header={t('FeaturedWidget.title')}
      withPadding={isMobile}
      pHeader={{ base: '20px', lg: 0 }}
      rightAction={
        isMobile ? undefined : (
          <Flex gap='20px'>
            <IconButton
              aria-label='back'
              variant='transparent'
              icon={<IconChevronLeft />}
              onClick={() => controlledSwiperFeatured?.slidePrev()}
            />
            <IconButton
              aria-label='next'
              variant='transparent'
              icon={<IconChevronRight />}
              onClick={() => controlledSwiperFeatured?.slideNext()}
            />
          </Flex>
        )
      }
    >
      {isLoading && (
        <Box
          position='relative'
          w='full'
          h='200px'
        >
          <PageLoader />
        </Box>
      )}
      {isMobile ? (
        <MobileHomeFeaturedList
          products={data?.items || []}
          onToggleFavoriteFeature={handleToggleFavoriteFeature}
        />
      ) : (
        <DesktopHomeFeaturedList
          products={data?.items || []}
          onToggleFavoriteFeature={handleToggleFavoriteFeature}
          controlledSwiperFeatured={controlledSwiperFeatured || undefined}
          setControlledSwiperFeatured={setControlledSwiperFeatured}
        />
      )}
    </Section>
  );
};

export default FeaturedWidget;
