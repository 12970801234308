import { Box, Flex, ResponsiveValue, Text } from '@chakra-ui/react';
import { ElementType, ReactNode } from 'react';
import {
  defaultPaddingDesktop,
  paddingDesktop,
  textStyleConfigDesktop,
  textStyleConfigMobile,
  paddingTopDesktop,
  defaultPaddingTopDesktop,
} from './constants';
import { HeaderType } from './types';

type Props = {
  children: ReactNode;
  headerType: HeaderType;
  variant?: 'dark' | 'white';
  rightElement?: ReactNode;
  withPadding?: boolean;
  withPaddingTop?: boolean;
  pHeader?: ResponsiveValue<number | (string & {})>;
  as?: ElementType;
};

export const PanelHeader = ({
  children,
  variant = 'dark',
  rightElement,
  headerType,
  withPadding,
  pHeader,
  withPaddingTop = true,
  as,
}: Props) => {
  const pd = withPaddingTop ? paddingTopDesktop : paddingDesktop;
  const dpd = withPaddingTop ? defaultPaddingTopDesktop : defaultPaddingDesktop;

  return (
    <Flex
      justifyContent='space-between'
      alignItems='center'
      color={
        variant === 'white' ? 'text&icon.tx-primary' : 'text&icon.tx-contrast'
      }
    >
      <Box
        p={{
          base: '0',
          lg: withPadding ? pd[headerType] : dpd[headerType],
        }}
      >
        <Text
          as={as}
          textStyle={{
            base: textStyleConfigMobile[headerType],
            lg: textStyleConfigDesktop[headerType],
          }}
          p={pHeader}
        >
          {children}
        </Text>
      </Box>
      <Box pr={{ base: '20px', lg: withPadding ? '40px' : '0' }}>
        {rightElement !== undefined && rightElement}
      </Box>
    </Flex>
  );
};

export default PanelHeader;
