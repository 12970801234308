import { Snippet } from '@/features/audio-player/types';
import {
  CrowdFundingCampaignStatusEnum,
  Product,
} from '@/shared/api/codegen/demuzo';
import { CampaignCard } from '@/shared/ui-kit/organisms/campaign-card';
import {
  calculateDaysLeft,
  calculateProgressStartDay,
} from '@/shared/utils/calculate-days-left';
import hardPush from '@/shared/utils/hard-push';
import { Box } from '@chakra-ui/react';
import { FC, MouseEvent, useCallback } from 'react';
import { Controller, FreeMode } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Swiper as SwiperType } from 'swiper/types';
import { useIsMobile } from '@ui-kit/hooks/useIsMobile';
import { usePlayerData } from '@/features/audio-player/hooks/use-player-data';
import { usePlayerControls, usePlayList } from '@/features/audio-player';
import { signIn, useSession } from 'next-auth/react';

const swiperModules = [FreeMode, Controller];

type Props = {
  products: (Omit<Product, 'snippets'> & { snippets: Snippet[] })[];
  onToggleFavoriteFeature: ({
    evt,
    productId,
    isFavorite,
    isLogged,
  }: {
    evt: MouseEvent<HTMLButtonElement>;
    productId: string;
    isFavorite: boolean;
    isLogged: boolean;
  }) => void;
  controlledSwiperFeatured: SwiperType | undefined;
  setControlledSwiperFeatured: (swiper: SwiperType) => void;
};

export const DesktopHomeFeaturedList: FC<Props> = ({
  products,
  onToggleFavoriteFeature,
  controlledSwiperFeatured,
  setControlledSwiperFeatured,
}) => {
  const isMobile = useIsMobile();
  const session = useSession();
  const { updateData } = usePlayerData();
  const { setPlayList } = usePlayList();
  const { play, pause, checkIsActiveTrack } = usePlayerControls();

  const handlePlay = (
    snippet: Snippet,
    campaignId: string,
    isFavorite: boolean,
    canBuy: boolean,
  ) => {
    updateData({
      currentCampaignId: campaignId,
      isFavoriteCampaign: isFavorite,
      canBuy,
    });
    checkIsActiveTrack(snippet) ? pause() : play();
  };
  const onBuyClick = useCallback(
    async (id: string) => {
      const callbackUrl = `/project/purchase/${id}`;
      if (session.status === 'unauthenticated') {
        await signIn('keycloak', { callbackUrl });
        return;
      }

      hardPush(callbackUrl);
    },
    [session],
  );

  return (
    <Box mt='20px'>
      <Swiper
        spaceBetween={20}
        slidesPerView='auto'
        freeMode={true}
        grabCursor
        modules={swiperModules}
        onSwiper={setControlledSwiperFeatured}
        controller={{ control: controlledSwiperFeatured }}
      >
        {products.map((product, index) => {
          const snippet = product.snippets.at(0);

          const handleCurrentPlay = () => {
            updateData({
              currentCampaignId: product.id,
              isFavoriteCampaign: product.isFavorite,
              canBuy: product.crowdFundingCampaign.status === 'in_progress',
            });
          };
          const genres = product.secondaryGenre
            ? `${product.primaryGenre} · ${product.secondaryGenre}`
            : product.primaryGenre;
          return (
            <SwiperSlide
              key={product.id}
              virtualIndex={index}
              style={{ width: 445, paddingBottom: isMobile ? 20 : 0 }}
            >
              <CampaignCard
                isFeatured
                snippet={snippet}
                size='lg'
                href={`/project/${product.id}`}
                imageUrl={product.coverUrls.at(0) ?? ''}
                onPlay={handleCurrentPlay}
                onToggleFavoriteFeature={(evt) => {
                  onToggleFavoriteFeature({
                    evt,
                    productId: product.id,
                    isFavorite: product.isFavorite,
                    isLogged: true,
                  });
                }}
                canBuy={
                  product.crowdFundingCampaign.status ===
                  CrowdFundingCampaignStatusEnum.in_progress
                }
                onBuy={() => {
                  onBuyClick(product.id);
                }}
                isFavorite={product.isFavorite}
                totalInvested={product.crowdFundingCampaign.totalInvested}
                daysLeft={calculateDaysLeft(
                  product.crowdFundingCampaign.startDateTime,
                  product.crowdFundingCampaign.endDateTime,
                )}
                limit={product.crowdFundingCampaign.successThreshold}
                progress={Number(product.crowdFundingCampaign.progress)}
                crowdFundingCampaignStatus={product.crowdFundingCampaign.status}
                progressToStart={
                  product.crowdFundingCampaign.createdAt
                    ? calculateProgressStartDay(
                        product.crowdFundingCampaign.createdAt,
                        product.crowdFundingCampaign.startDateTime,
                      )
                    : 1
                }
                artists={product.artist}
                productName={product.name}
                genres={genres}
                sharePrice={product.crowdFundingCampaign.sharePrice}
              />
            </SwiperSlide>
          );
        })}
      </Swiper>
    </Box>
  );
};
