import { HeaderStyleConfig, PaddingStyleConfig } from './types';

export const textStyleConfigMobile: HeaderStyleConfig = {
  h1: 'heading-small-3xl',
  h2: 'heading-small-xl',
  h3: 'heading-small-lg',
  h4: 'heading-small-md',
};

export const textStyleConfigDesktop: HeaderStyleConfig = {
  h1: 'heading-large-3xl',
  h2: 'heading-large-xl',
  h3: 'heading-large-lg',
  h4: 'heading-large-md',
};

export const defaultPaddingTopDesktop: PaddingStyleConfig = {
  h1: '32.5px 0',
  h2: '31px 0',
  h3: '23px 0',
  h4: '24.5px 0',
};

export const paddingTopDesktop: PaddingStyleConfig = {
  h1: '32.5px 40px',
  h2: '31px 40px',
  h3: '23px 40px',
  h4: '24.5px 40px',
};

export const defaultPaddingDesktop: PaddingStyleConfig = {
  h1: '0',
  h2: '0',
  h3: '0',
  h4: '0',
};

export const paddingDesktop: PaddingStyleConfig = {
  h1: '0 40px',
  h2: '0 40px',
  h3: '0 40px',
  h4: '0 40px',
};
