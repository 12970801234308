export {
  getProductV1FeaturedConfig,
  useGetFeatured,
} from './model/hooks/use-get-featured';
export {
  getProductByIdPrefetchConfig,
  useGetProductById,
} from './model/hooks/use-get-product-by-id';
export {
  getProductsPrefetchConfig,
  useGetProducts,
} from './model/hooks/use-get-products';
export {
  getProductsAllPrefetchConfig,
  useGetProductsAll,
} from './model/hooks/use-get-products-all';
export { usePostProduct } from './model/hooks/use-post-product';
export { usePutProduct } from './model/hooks/use-put-product';
