import { Box, BoxProps, forwardRef } from '@chakra-ui/react';

export const GradientBox = forwardRef<BoxProps, 'div'>(
  (
    {
      children,
      m,
      mt,
      margin,
      marginTop,
      rounded = 20,
      pos,
      position,
      ...props
    },
    ref,
  ) => {
    return (
      <Box
        m={m}
        mt={mt}
        margin={margin}
        marginTop={marginTop}
        w='full'
        p='1px'
        layerStyle='gradient-border'
        rounded={rounded}
        pos={pos}
        position={position}
      >
        <Box
          ref={ref}
          w='full'
          bgColor='background.bg-primary'
          rounded={rounded}
          overflow='hidden'
          {...props}
        >
          {children}
        </Box>
      </Box>
    );
  },
);

export default GradientBox;
