import { REVALIDATE } from '@/entities/constants';
import {} from '@/shared/api';
import { FetchQueryOptions, useQuery } from '@tanstack/react-query';
import { mapApiProductToProduct } from '../lib';
import { productsKeys } from '../query-keys';
import { Product } from '@/shared/api/codegen/demuzo';
import { productApi } from '@/shared/api/config';

type Props = {
  productId: string;
  initialData?: Product;
};

export const getProductByIdPrefetchConfig = (
  productId: string,
  headers?: any,
) =>
  ({
    queryKey: [...productsKeys.getProductById(productId)],
    queryFn: async () => {
      const response = await productApi.getProductsV1ProductId(
        { productId },
        { headers, next: { revalidate: REVALIDATE } },
      );

      const mappedProducts = mapApiProductToProduct(response);

      return mappedProducts;
    },
  }) satisfies FetchQueryOptions;

export const useGetProductById = ({ productId, initialData }: Props) => {
  return useQuery({
    queryKey: [...productsKeys.getProductById(productId)],
    queryFn: async () => {
      const response = await productApi.getProductsV1ProductId({ productId });

      const mappedProducts = mapApiProductToProduct(response);

      return mappedProducts;
    },
    initialData: initialData ? mapApiProductToProduct(initialData) : undefined,
    refetchOnMount: !initialData,
  });
};
