'use client';

import { AbsoluteCenter, Spinner } from '@chakra-ui/react';

export const LoaderPage = () => {
  return (
    <AbsoluteCenter>
      <Spinner />
    </AbsoluteCenter>
  );
};

export default LoaderPage;
