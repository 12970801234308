import { REVALIDATE } from '@/entities/constants';
import {} from '@/shared/api';
import { FetchQueryOptions, useQuery } from '@tanstack/react-query';
import { mapApiProductToProduct } from '../lib';
import { productsKeys } from '../query-keys';
import { GetProducts200 } from '@/shared/api/codegen/demuzo';
import { productApi } from '@/shared/api/config';

type Props = {
  limit: number;
  offset: number;
  initData?: GetProducts200;
};

export const getProductsAllPrefetchConfig = () =>
  ({
    queryKey: [...productsKeys.getProductsAll({ limit: 10, offset: 0 })],
    queryFn: async () => {
      const response = await productApi.getProductsV1All(
        { limit: 10, offset: 0 },
        { next: { revalidate: REVALIDATE } },
      );

      return response.items.map(mapApiProductToProduct);
    },
  }) satisfies FetchQueryOptions;

export const useGetProductsAll = ({ limit, offset, initData }: Props) => {
  return useQuery({
    queryKey: [...productsKeys.getProductsAll({ limit, offset })],
    queryFn: async () => {
      const response = await productApi.getProductsV1All({ offset, limit });
      return response.items.map(mapApiProductToProduct);
    },
    initialData: initData?.items.map(mapApiProductToProduct),
    refetchOnMount: !initData,
  });
};
